import {APIPagination} from "../tools/pagination/APIPagination";
import {Criterion} from "../tools/model/pagination/Criterion";

export class ProfileAPIPagination extends APIPagination {
    /**
     * setCriterion
     * @param criterion
     * @returns {undefined}
     */
    public setCriterion(criterion: Criterion<any>): void {
        super.setCriterion(criterion);
    }
}