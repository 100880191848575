import {AbstractDTO} from "../../../tools/model/dto/AbstractDTO";
import Category from "../../Category";

export class CategoryDTO extends AbstractDTO {
    static TITLE = "title"
    static ORDER = "order"

    constructor(title: string, order: number) {
        super();
        this.title = title;
        this.order = order;
    }

    title: string;
    order: number;

    static fromCategory = (category: Category): CategoryDTO => {
        return new CategoryDTO(category.title, category.order)
    };
}