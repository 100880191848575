import {AbstractDTO} from "../../../tools/model/dto/AbstractDTO";
import Video from "../../Video";

export class VideoMetaDataDTO extends AbstractDTO {
    static TITLE = "title"
    static SUBTITLE = "subtitle"
    static DESCRIPTION = "description"
    static LENGTH_S = "lengthS"
    static PUBLISHED = "published"

    constructor(title: string, subtitle: string, description: string, lengthS: number, published: boolean) {
        super();
        this.title = title;
        this.subtitle = subtitle;
        this.description = description;
        this.lengthS = lengthS;
        this.published = published;
    }

    title: string
    subtitle: string
    description: string
    lengthS: number
    published: boolean

    static fromVideo = (video: Video): VideoMetaDataDTO => {
        return new VideoMetaDataDTO(
            video.title,
            video.subtitle,
            video.description,
            video.lengthS,
            video.published
        )
    };
}