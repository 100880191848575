import {AbstractDTO} from "../../../tools/model/dto/AbstractDTO";
import Video from "../../Video";
import VideoService from "../../../services/VideoService";
import {VideoFileType} from "../../../component/page/VideoPage";

export class VideoFileUploadDTO extends AbstractDTO {

    constructor(fileUrl: string, fileType: VideoFileType) {
        super();

        this.fileUrl = fileUrl;
        this.fileType = fileType;
    }

    fileUrl: string
    fileType: VideoFileType

    static fromVideo = (video: Video, fileType: VideoFileType, videoService: VideoService): VideoFileUploadDTO => {
        return new VideoFileUploadDTO(videoService.getFileURL(fileType, video), fileType)
    };
}