import Catalog from "../models/Catalog";
import QueryFilter from "../tools/model/pagination/Filters";
import CatalogService from "../services/CatalogService";
import {PaginationController} from "../tools/pagination/PaginationController";
import {DataProvider} from "../tools/provider/DataProvider";
import {CatalogAPIPagination} from "../pagination/CatalogAPIPagination";
import {PaginationFilters} from "../tools/pagination/PaginationFilters";

interface CatalogPaginationFilters extends PaginationFilters {
    grantType?: string
}

export default class CatalogDataProvider extends DataProvider<Catalog, CatalogAPIPagination>{
    private readonly _catalogService: CatalogService;

    /**
     * constructor
     * @param catalogService
     */
    public constructor(catalogService: CatalogService) {
        super(new PaginationController<Catalog, CatalogAPIPagination>(new CatalogAPIPagination()));

        this._catalogService = catalogService;
    }

    /**
     * load
     * @returns {Promise<Catalog[]>}
     */
    async load() : Promise<Catalog[]> {
        const pagination = this._paginationController.pagination;

        const paginationFilters: QueryFilter<CatalogPaginationFilters> = new QueryFilter<CatalogPaginationFilters>({
            page: pagination.page || 0,
            size: pagination.limit || 10
        });

        if (pagination.sort) paginationFilters.fields.sort = `${pagination.sort.by},${pagination.sort.order.toString()}`
        if (pagination.searchQuery) paginationFilters.fields.searchQuery = pagination.searchQuery;

        const pageResponse = await this._catalogService.getPage<Catalog>(CatalogService.LIST_ROUTE, paginationFilters);
        pagination.updateWithPageResponse(pageResponse);

        this.notifySubscribers(pageResponse.content);

        return pageResponse.content;
    }
}