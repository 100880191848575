import Profile from "../models/Profile";
import QueryFilter from "../tools/model/pagination/Filters";
import ProfileService from "../services/ProfileService";
import {PaginationController} from "../tools/pagination/PaginationController";
import {DataProvider} from "../tools/provider/DataProvider";
import {ProfileAPIPagination} from "../pagination/ProfileAPIPagination";
import {PaginationFilters} from "../tools/pagination/PaginationFilters";

interface ProfilePaginationFilters extends PaginationFilters {
    grantType?: string
}

export default class ProfileDataProvider extends DataProvider<Profile, ProfileAPIPagination>{
    private readonly _profileService: ProfileService;

    /**
     * constructor
     * @param profileService
     */
    public constructor(profileService: ProfileService) {
        super(new PaginationController<Profile, ProfileAPIPagination>(new ProfileAPIPagination()));

        this._profileService = profileService;
    }

    /**
     * load
     * @returns {Promise<Profile[]>}
     */
    async load() : Promise<Profile[]> {
        const pagination = this._paginationController.pagination;

        const paginationFilters: QueryFilter<ProfilePaginationFilters> = new QueryFilter<ProfilePaginationFilters>({
            page: pagination.page || 0,
            size: pagination.limit || 10
        });

        if (pagination.sort) paginationFilters.fields.sort = `${pagination.sort.by},${pagination.sort.order.toString()}`
        if (pagination.searchQuery) paginationFilters.fields.searchQuery = pagination.searchQuery;

        const pageResponse = await this._profileService.getPage<Profile>(ProfileService.LIST_ROUTE, paginationFilters);
        pagination.updateWithPageResponse(pageResponse);

        this.notifySubscribers(pageResponse.content);

        return pageResponse.content;
    }
}