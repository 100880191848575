import Button from "../view/element/button/Button";
import React from "react";
import HiloReactComponent from "../view/layout/HiloReactComponent";
import PaginationComponent from "../view/element/PaginationComponent";
import {Entity} from "../model/pagination/Entity";
import {PaginationController} from "../pagination/PaginationController";
import {APIPagination} from "../pagination/APIPagination";
import {ButtonProps} from "../view/element/button/ButtonProps";

export enum GroupAction {
    REMOVE
}

export interface ToolbarConfig<E extends Entity, P extends APIPagination> {
    paginationController?: PaginationController<E, P>;
    onAddClick?: () => void;
    onSearchQueryUpdated?: (query: string) => void;
    accessoryButton?: ButtonProps;
}

interface State {
    searchEnabled: boolean;
    searchQuery: string;
}

export default class ListToolbar<E extends Entity, P extends APIPagination> extends HiloReactComponent<ToolbarConfig<E, P>, State> {
    constructor(config: ToolbarConfig<E, P>, state: State) {
        super(config, state);

        this.state = {
            searchEnabled: false,
            searchQuery: ""
        }
    }

    componentDidMount(): void {
        super.componentDidMount();
    }

    /**
     * _toggleSearchMode
     * @private
     */
    private _toggleSearchMode = () => {
        this.setState({
            searchEnabled: !this.state.searchEnabled
        });
    }

    /**
     * _handleInputChange
     * @param e
     * @private
     */
    private _handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;

        this.props.onSearchQueryUpdated?.(value);
        this.setState({searchQuery: value});
    }

    /**
     * _pagination
     * @param props
     * @returns {any}
     * @private
     */
    private _pagination = (props: { pagination?: APIPagination }): any => {
        const {paginationController} = this.props;

        if (!paginationController) {
            return <></>;
        }

        const {pagination} = paginationController;

        return <PaginationComponent
            paginationController={paginationController}
            key={pagination?.page}
        />
    };

    /**
     * render
     * @returns {any}
     */
    public render(): React.ReactNode {
        const {searchQuery, searchEnabled} = this.state;
        const {paginationController, accessoryButton, onSearchQueryUpdated} = this.props;

        return <div className={"toolbar"}>
            <div className={"group"}>
                {this.props.onAddClick && <img src={"/img/add.png"} alt={"add"} onClick={this.props.onAddClick}/>}
                <this._pagination pagination={paginationController?.pagination}/>
            </div>
            <div className={"group"}>
                {!!onSearchQueryUpdated && searchEnabled && <div className={"search"}>
                    <img alt={"magnifier"} src={"/img/magnifier_glass_grey.png"}/>
                    <input type={"text"}
                           name={"search"}
                           placeholder={"Jean Dupont"}
                           value={searchQuery}
                           onChange={this._handleInputChange}/>
                </div>
                }

                {!!onSearchQueryUpdated && <img alt={"search"}
                                              src={searchEnabled ? "/img/search_inverted.png" : "/img/search.png"}
                                              onClick={this._toggleSearchMode}
                />}
                {accessoryButton && <Button color={accessoryButton.color}
                                            labelAccessor={accessoryButton.labelAccessor}
                                            disabled={accessoryButton.disabled}
                                            onClick={accessoryButton.onClick}
                                            loading={accessoryButton.loading}
                                            key={accessoryButton.key}
                                            outline={accessoryButton.outline}
                                            icon={accessoryButton.icon}
                                            fullwidth={accessoryButton.fullwidth}/>}
            </div>
        </div>
    }
}