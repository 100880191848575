import {AbstractDTO} from "../../../tools/model/dto/AbstractDTO";
import Catalog from "../../Catalog";

export class CatalogDTO extends AbstractDTO {
    static TITLE = "title"

    constructor(title: string) {
        super();
        this.title = title;
    }

    title: string;

    static fromCatalog = (catalog: Catalog): CatalogDTO => {
        return new CatalogDTO(catalog.title)
    };
}