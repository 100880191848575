import HiloReactComponent from "../layout/HiloReactComponent";
import React from "react";
import Error from "../element/Error";
import {Field} from "../../model/form/Field";

interface Props {
    field?: Field<any>
}

export default class FormError extends HiloReactComponent<Props, {}> {
    public render(): React.ReactNode {
        const {field} = this.props;

        if (!field) {
            return <></>
        }

        return <Error content={t => {
            return `${field?.title(t)} : ${field?.errorLabel?.(t) || t.formInvalidField}`
        }} />
    }
}