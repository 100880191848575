import Category from "../models/Category";
import QueryFilter from "../tools/model/pagination/Filters";
import CategoryService from "../services/CategoryService";
import {PaginationController} from "../tools/pagination/PaginationController";
import {DataProvider} from "../tools/provider/DataProvider";
import {CategoryAPIPagination} from "../pagination/CategoryAPIPagination";
import {PaginationFilters} from "../tools/pagination/PaginationFilters";

interface CategoryPaginationFilters extends PaginationFilters {
    grantType?: string
}

export default class CategoryDataProvider extends DataProvider<Category, CategoryAPIPagination>{
    private readonly _categoryService: CategoryService;

    /**
     * constructor
     * @param categoryService
     */
    public constructor(categoryService: CategoryService) {
        super(new PaginationController<Category, CategoryAPIPagination>(new CategoryAPIPagination()));

        this._categoryService = categoryService;
    }

    /**
     * load
     * @returns {Promise<Category[]>}
     */
    async load() : Promise<Category[]> {
        const pagination = this._paginationController.pagination;

        const paginationFilters: QueryFilter<CategoryPaginationFilters> = new QueryFilter<CategoryPaginationFilters>({
            page: pagination.page || 0,
            size: pagination.limit || 10
        });

        if (pagination.sort) paginationFilters.fields.sort = `${pagination.sort.by},${pagination.sort.order.toString()}`
        if (pagination.searchQuery) paginationFilters.fields.searchQuery = pagination.searchQuery;

        const pageResponse = await this._categoryService.getPage<Category>(CategoryService.LIST_ROUTE, paginationFilters);
        pagination.updateWithPageResponse(pageResponse);

        this.notifySubscribers(pageResponse.content);

        return pageResponse.content;
    }
}