import HiloReactComponent from "../layout/HiloReactComponent";
import FormSelect from "./FormSelect";
import React from "react";
import {Field} from "../../model/form/Field";
import {SelectField} from "../../model/form/SelectField";
import {CheckboxField} from "../../model/form/CheckboxField";
import FormCheckbox from "./FormCheckbox";
import FormInput, {FormInputEvent} from "./FormInput";
import {LongTextField} from "../../model/form/LongTextField";
import FormTextArea from "./FormTextArea";
import {FileField} from "../../model/form/FileField";
import FormFileInput from "./FormFileInput";
import {DownloadField} from "../../model/form/DownloadField";
import FormFileDownload from "./FormFileDownload";

export abstract class FormElementProps<D> {
    abstract field: Field<D>
    abstract onChange?: FormInputEvent<D>;
}

export abstract class FormElement<D> extends HiloReactComponent<FormElementProps<D>, any> {

    render(): React.ReactNode {
        const {field, onChange} = this.props;

        if (field instanceof SelectField) {
            return <FormSelect key={field.name} field={field}  onChange={onChange}/>
        } else if (field instanceof LongTextField) {
            return <FormTextArea key={field.name} field={field}  onChange={onChange}/>
        } else if (field instanceof FileField) {
            return <FormFileInput key={field.name} field={field}  onChange={onChange}/>
        } else if (field instanceof DownloadField) {
            return <FormFileDownload key={field.name} field={field}  onChange={undefined}/>
        } else if (field instanceof CheckboxField) {
            return <FormCheckbox key={field.name} field={field} onChange={onChange}/>;
        } else {
            return <FormInput key={field.name} field={field} onChange={onChange}/>;
        }
    }
}
