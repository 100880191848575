import {Field} from "./Field";
import {LabelAccessor} from "../../view/LabelAccessor";
import {Type} from "../../view/form/FormInput";

export class DownloadField extends Field<string> {

    constructor(name: string,
                title: LabelAccessor,
                isValid: (value: string | string[]) => boolean,
                type: Type,
                required: boolean,
                value: string | undefined,
                defaultValue: string | undefined,
                disabled: boolean = false,
                url?: string,
                errorLabel?: LabelAccessor
    ) {
        super(name, title, isValid, type, required, undefined, undefined, disabled, errorLabel);
        this.value = url
    }

    set url(value: string | undefined) {
        this.value = value
    }

    get url(): string | undefined {
        return this.value?.toString()
    }

    stringValue = (): string | undefined => {
        if (this.value && this.value instanceof File) {
            return this.value.name
        }
        return undefined
    };
}