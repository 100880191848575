import {AbstractDTO} from "../../../tools/model/dto/AbstractDTO";
import Catalog from "../../Catalog";

export class VideoCatalogSelectDTO extends AbstractDTO {
    static CATALOGS = "catalogs"

    constructor(catalogs: Catalog[]) {
        super();
        this.catalogs = catalogs;
    }

    catalogs: Catalog[];

    static fromCatalogs = (catalogs: Catalog[]): VideoCatalogSelectDTO => {
        return new VideoCatalogSelectDTO(catalogs)
    };
}