import {AbstractDTO} from "../../../tools/model/dto/AbstractDTO";
import Profile from "../../Profile";

export class ProfileDTO extends AbstractDTO {
    static NAME = "name"
    static UUID = "uuid"
    static CATALOG_ID = "catalogId"

    constructor(name: string, uuid: string, catalogId?: string) {
        super();
        this.name = name;
        this.uuid = uuid;
        this.catalogId = catalogId;
    }

    name: string;
    uuid: string;
    catalogId?: string;

    static fromProfile = (profile: Profile): ProfileDTO => {
        console.log("from profile catalogId", profile.catalogId)
        return new ProfileDTO(profile.name, profile.userId, profile.catalogId)
    };
}