import QueryFilter from "../tools/model/pagination/Filters";
import {PaginationController} from "../tools/pagination/PaginationController";
import {DataProvider} from "../tools/provider/DataProvider";
import VideoService from "../services/VideoService";
import Video from "../models/Video";
import Category from "../models/Category";
import {APIPagination, DefaultAPIPagination} from "../tools/pagination/APIPagination";
import KareviHTTPService from "../tools/service/KareviHTTPService";
import {PaginationFilters} from "../tools/pagination/PaginationFilters";

interface VideoPaginationFilters extends PaginationFilters {
    published: boolean
}

export default class VideoDataProvider extends DataProvider<Video, APIPagination> {
    private readonly _videoService: VideoService;
    private readonly _httpService: KareviHTTPService;
    private _category?: Category;

    /**
     * constructor
     * @param videoService
     * @param httpService
     * @param category
     */
    constructor(videoService: VideoService, httpService: KareviHTTPService, category?: Category) {
        super(new PaginationController<Video, APIPagination>(new DefaultAPIPagination()));

        this._httpService = httpService;
        this._videoService = videoService;
        this._category = category;
    }

    /**
     * load
     * @returns {Promise<Video[]>}
     */
    async load(): Promise<Video[]> {
        const pagination = this._paginationController.pagination;

        const paginationFilters = new QueryFilter<VideoPaginationFilters>({
            page: pagination.page || 0,
            size: pagination.limit || 10,
            published: false
        });

        if (pagination.sort) paginationFilters.fields.sort = `${pagination.sort.by},${pagination.sort.order.toString()}`

        if (this._category == null) return [];

        const url = this._httpService.buildRoute(VideoService.LIST_ROUTE, {categoryId: this._category.id})

        const pageResponse = await this._videoService.getPage<Video>(url, paginationFilters);
        this.paginationController.pagination.updateWithPageResponse(pageResponse);

        this.notifySubscribers(pageResponse.content);

        return pageResponse.content;
    }


    /**
     * category
     * @param category
     */
    public set category(category: Category) {
        this._category = category;
        this.load();
    }
}